import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from '@core/scripts/helper/jsh-module';
import NavigationLarge from '../../components/navigation/NavigationLarge.jsx';
import NavigationModel from '../../models/navigation-model.js';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const {moduleScope, config} = JshModule('navigation-large-new');

const navigation = NavigationModel.load();

if (navigation && navigation.navigationItems) {
    const queryClient = new QueryClient();
    const {currentPage} = config;

    createRoot(moduleScope.parentNode).render(
        <QueryClientProvider client={queryClient}>
            <NavigationLarge
                currentPage={currentPage}
                navigationItems={navigation.navigationItems}
                texts={navigation.texts}
            />
        </QueryClientProvider>,
    );
}
